<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <div>
      <div class="back-icon">
        <img
          src="../../assets/previous.svg"
          width="30"
          @click="$router.back()"
        />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("maintenance_plan.maintenance_plan") }}</div>
      </h1>
      <div class="container">
        <form @submit.prevent="editPlan">
          <div class="row justify-content-center align-items-stretch">
            <div class="col-12 col-md-6 my-2">
              <div class="create-card">
                <p class="text-big">{{ $t("maintenance_plan.create_plan") }}</p>
                <p class="text-big">{{ $t("maintenance_plan.plan_detail") }}</p>
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.name")
                  }}</label>
                  <input
                    type="text"
                    class="w-100"
                    v-model="planName"
                    required
                  />
                </div>
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.vendor")
                  }}</label>
                  <!-- <v-select class="search-select"></v-select> -->
                  <select
                    class="dropdown form-control w-100"
                    v-model="vendorId"
                  >
                    <option :value="null">กรุณาเลือก</option>
                    <option
                      v-for="vendor in vendorList"
                      :key="vendor.vendor_id"
                      :value="vendor.vendor_id"
                      >{{ vendor.name }}</option
                    >
                  </select>
                </div>
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.detail")
                  }}</label>
                  <input
                    type="text"
                    class="w-100"
                    v-model="planDesc"
                    required
                  />
                </div>
                <div class="d-flex justify-content-between text-grey">
                  <p>{{ $t("maintenance_plan.asset") }}</p>
                  <p>
                    {{ getSelectedAsset.length }}
                    {{ $t("maintenance_plan.item") }}
                  </p>
                </div>
                <div v-if="getSelectedAsset.length > 0">
                  <div
                    class="asset-card"
                    v-for="(asset, index) in getSelectedAsset"
                    :key="asset.id_asset"
                  >
                    <img
                      v-if="asset.image_url"
                      :src="asset.image_url.url"
                      alt=""
                    />
                    <img v-else :src="noimg" alt="" />
                    <div>
                      <p>
                        <b>{{ asset.name }}</b>
                      </p>
                      <p>{{ asset.id_fixed_asset }}</p>
                    </div>
                    <b-icon-x
                      scale="1.5"
                      class="ms-auto"
                      style="cursor: pointer;"
                      @click="removeAsset(index)"
                    />
                  </div>
                </div>
                <p class="add-asset" @click="addAsset">
                  + {{ $t("maintenance_plan.add_asset") }}
                </p>
                <div class="mt-4">
                  <button type="submit" class="btn mx-2">
                    {{ $t("btn.conf") }}
                  </button>
                  <button
                    class="btn mx-2"
                    type="button"
                    style="color: #fff;  background: #7c7c7c;"
                    @click="$router.back()"
                  >
                    {{ $t("btn.canc") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-2">
              <div class="create-card">
                <p class="text-big">{{ $t("maintenance_plan.schedule") }}</p>
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.start_date")
                  }}</label>
                  <date-picker
                    class="inline-block datetime-pick"
                    v-model="startDate"
                    mode="datetime"
                    locale="en"
                    is24hr
                    is-required
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="row">
                        <div class="col-12">
                          <input
                            style="cursor: pointer;"
                            :value="inputValue | formatDateTime"
                            class="form-control px-20"
                            readonly
                            @click="togglePopover()"
                          />
                        </div>
                      </div>
                    </template>
                  </date-picker>
                </div>
                <hr style="border-bottom: 1px solid #7c7c7c" />
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.loop_for")
                  }}</label>
                  <div class="d-flex align-items-center">
                    <input
                      type="number"
                      class="w-100"
                      step="1"
                      v-model="loopValue"
                    />
                    <p class="text-grey m-0">
                      {{ $t("maintenance_plan.month") }}
                    </p>
                  </div>
                </div>
                <hr style="border-bottom: 1px solid #7c7c7c" />
                <div class="my-2">
                  <label class="text-grey">{{
                    $t("maintenance_plan.end_date")
                  }}</label>
                  <date-picker
                    class="inline-block h-full"
                    v-model="endDate"
                    locale="en"
                    is-required
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="row">
                        <div class="col-12">
                          <input
                            style="cursor: pointer;"
                            :value="inputValue | formatDate"
                            class="form-control px-20"
                            readonly
                            @click="togglePopover()"
                          />
                        </div>
                      </div>
                    </template>
                  </date-picker>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="line-break"></div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import { baseUrl } from "../../util/backend";
import axios from "axios";
import { authHeader } from "../../store/actions";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: { Footer, DatePicker },
  data() {
    return {
      prevRoute: null,
      planId: null,
      id_company: null,
      planName: "",
      vendorId: null,
      planDesc: "",
      startDate: null,
      endDate: null,
      loopValue: 1,
      noimg: require("../../image/Not-regis-qr-code.png"),

      vendorList: [],
    };
  },

  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
      getSelectedAsset: "getSelectedMaintenanceAsset",
      getMaintenancePlanData: "getMaintenancePlanData",
    }),
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
    formatDateTime(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      console.log(from);
    });
  },
  async mounted() {
    this.planId = this.$route.params.planId;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.loadData();
  },
  methods: {
    addAsset() {
      this.$router.push({
        name: "All_asset",
        query: {
          page: "maintenance_plan",
        },
      });
    },
    removeAsset(index) {
      this.$store.dispatch("removeMaintenancePlanAsset", index);
    },
    async editPlan() {
      if (!this.startDate || !this.endDate) {
        alert(this.$t("maintenance_plan.alert_no_schedule"));
        return;
      }
      const assetArray = [...this.getSelectedAsset];
      if (assetArray.length <= 0) {
        Swal.fire({
          icon: "error",
          title: this.$t("dialog.error"),
          text: this.$t("dialog.sel_asset"),
          type: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        return;
      }
      try {
        await axios.put(
          `${baseUrl()}maPlan/updateMaPlan`,
          {
            name: this.planName,
            vendor_id: this.vendorId,
            id_company: this.id_company,
            id_assets: assetArray.map((x) => x.id_asset),
            start_date: moment(this.startDate).toISOString(),
            end_date: moment(this.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString(),
            range_of_recurring_month: this.loopValue,
            detail: this.planDesc,
            preventive_ma_plan_id: this.planId,
          },
          authHeader()
        );
        this.$router.back();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
    },
    async loadData() {
      try {
        if (
          this.prevRoute?.name === "All_asset" &&
          this.getMaintenancePlanData
        ) {
          this.planName = this.getMaintenancePlanData.planName;
          this.vendorId = this.getMaintenancePlanData.vendorId;
          this.planDesc = this.getMaintenancePlanData.planDesc;
          this.startDate = this.getMaintenancePlanData.startDate;
          this.endDate = this.getMaintenancePlanData.endDate;
          this.loopValue = this.getMaintenancePlanData.loopValue;
        }
        const resVendor = await axios.get(
          `${baseUrl()}maPlan/getAllVendor?id_company=${this.id_company}`,
          authHeader()
        );
        this.vendorList = resVendor.data.data;
        const resPlan = await axios.get(
          `${baseUrl()}maPlan/getMaPlans?id_company=${
            this.id_company
          }&preventive_ma_plan_id=${this.planId}`,
          authHeader()
        );
        const plan = resPlan.data.data;
        if (this.prevRoute?.name !== "All_asset") {
          const selectedAsset = plan.preventive_ma_plan_assets.map((asset) => {
            return {
              id_asset: asset.id_asset,
              image_url: asset.asset.image_url,
              name: asset.asset.name,
              id_fixed_asset: asset.asset.id_fixed_asset,
            };
          });
          this.planName = plan.name;
          this.vendorId = plan.vendor_id;
          this.planDesc = plan.detail;
          this.startDate = plan.start_date;
          this.endDate = plan.end_date;
          this.loopValue = plan.range_of_recurring_month;
          this.$store.dispatch("setMaintenancePlanAsset", selectedAsset);
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
    },
  },
 
  beforeRouteLeave(to, _, next) {
    if (to.name === "All_asset" && to.query.page === "maintenance_plan") {
      this.$store.dispatch("setMaintenancePlanData", {
        planName: this.planName,
        vendorId: this.vendorId,
        planDesc: this.planDesc,
        startDate: this.startDate,
        endDate: this.endDate,
        loopValue: this.loopValue,
      });
      next();
    } else {
      this.$store.dispatch("setMaintenancePlanData", null);
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 5px 10px;
  height: fit-content;
  min-width: 120px;
}

p,
label {
  font-size: 14px;
}

label {
  display: block;
  text-align: left;
}

.text-grey {
  color: #7c7c7c;
}

input,
select,
textarea {
  border-radius: 8px !important;
  background-color: #e0e0e0;
  border: none;
  height: 30px;
}

.text-big {
  font-size: 18px;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 0px;
  }
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.create-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.search-select {
  ::v-deep .vs__search::placeholder,
  ::v-deep .vs__dropdown-toggle {
    background-color: #f4f4f4;
    border: none;
  }
}

.add-asset {
  cursor: pointer;
  color: #007afe;
  text-align: left;
  width: fit-content;
}
.datetime-pick {
  ::v-deep .vc-date {
    display: none;
  }
}

.asset-card {
  display: flex;
  align-items: center;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  img {
    width: 50px;
    height: 30px;
    object-fit: contain;
  }
  p {
    margin: 0;
    &:last-child {
      @extend .text-grey;
    }
  }
}
</style>
